import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './HamburguerMenu.css';

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div className="hamburger-icon" onClick={toggleMenu}>
        ☰
      </div>
      <nav className={`hamburger-menu ${isOpen ? 'open' : ''}`}>
        <ul>
          <li>
            <Link to="/mensagemBairro">Mensagem Bairro</Link>
          </li>
          <li>
            <Link to="/mensagemPON">Mensagem PON</Link>
          </li>
          <li>
            <Link to="/mensagemConhecerBot">Mensagem Conhecer Bot</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default HamburgerMenu;
