import React, { useState } from 'react';
import axios from 'axios';
import "./Form.css";

const EnviarMensagem: React.FC = () => {
  const [msg, setMsg] = useState<string>('');
  const [bairro, setBairro] = useState<string>('');
  const [password, setPassword] = useState<string>(''); // Estado para a senha

  const enviarMensagem = async () => {
    try {
      const response = await axios.post(
        `https://whatsemail.wiptelecomunicacoes.com.br/EnviaMensagem?msg=${encodeURIComponent(msg)}&bairro=${encodeURIComponent(bairro)}`,
        { password } // Envia a senha no corpo da requisição
      );
      if (response.status === 200) {
        alert('Mensagem enviada com sucesso!');
      } else {
        alert('Falha ao enviar a mensagem');
      }
    } catch (error) {
      console.error('Erro ao enviar a mensagem:', error);
      alert('Erro ao enviar a mensagem');
    }
  };

  return (
    <section>
        <div>
            <h1>Enviar Mensagem Bairro</h1>
            <input
                type="text"
                value={msg}
                onChange={(e) => setMsg(e.target.value)}
                placeholder="Digite a mensagem"
            />
            <input
                type="text"
                value={bairro}
                onChange={(e) => setBairro(e.target.value)}
                placeholder="Digite o bairro"
            />
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Digite a senha" // Campo para a senha
            />
            <button onClick={enviarMensagem}>Enviar</button>
        </div>
    </section>
  );
};

export default EnviarMensagem;
