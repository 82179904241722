import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import EnviarMensagem from './components/Form';
import EnviarMensagemConhecerBot from "./components/EnviarMensagemConhecerBot";
import EnviarMensagemPOM from './components/EnviarMensagemPON';
import HamburgerMenu from './components/HamburguerMenu';
import './App.css';

const App = () => {
  return (
    <Router>
      <div>
        <HamburgerMenu />
        <Routes>
          <Route path="/mensagemBairro" element={<EnviarMensagem />} />
          <Route path="/mensagemPON" element={<EnviarMensagemPOM />} />
          {/* <Route path="/mensagemConhecerBot" element={<EnviarMensagemConhecerBot />} /> */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;
